import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { users } from "../api/index";
import roleMapping from "../data/roleMapping";

const initialState = {
  user: {},
  loader: true,
  roles: [],
  brands: {},
  emailId: "",
  name: "",
  uuid: "",
};

const fetchUsers = createAsyncThunk("user/fetchUsers", async () => {
  const response = await users();
  if (
    !sessionStorage.getItem("role") ||
    sessionStorage.getItem("role") == null
  ) {
    sessionStorage.setItem("role", response.data.data.role[0]);
  }
  return response.data.data;
});

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.user = action.payload;
      state.roles = action.payload.role;
      state.brands = action.payload.brand;
      state.emailId = action.payload.email_id;
      state.uuid = action.payload.uuid;
      state.name =
        action.payload.first_name.trim() +
        " " +
        action.payload.last_name.trim();
      state.loader = false;
    });
    builder.addCase(fetchUsers.pending, (state, action) => {
      if (!Object.keys(state.user).length) {
        state.loader = true;
      }
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.user = action.payload;
      state.loader = false;
    });
  },
});

export { fetchUsers };

export default userSlice.reducer;
