const roleMapping = {
  spclty_hub_admin: 'Admin',
  spclty_hub_bia_team: 'BIA Team',
  spclty_hub_hcp_marketer: 'HCP Marketer',
  spclty_hub_hcp_sf_marketer: 'HCP SF Marketer',
  spclty_hub_mlr_approver: 'MLR Approver',
  spclty_hub_omnichannel_advisor: 'Medical Omnichannel',
  spclty_hub_incentivized_approver: 'Incentivized Approver',
  spclty_hub_viewer: 'Viewer',
  spclty_hub_super_admin: 'Super Admin',
  spclty_hub_mlr_reviewer: 'MLR Reviewer'
};

export default roleMapping;
