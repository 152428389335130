import { configureStore } from "@reduxjs/toolkit";
import user from "./user";
import overview from "./overview";
import details from "./details";
import admin from "./admin";
import approvedSpecialties from "./approvedSpecialties";
import specialties from "./specialties";
import error from "./error";

// export const store = configureStore({
//   reducer: { counter, [postsApi.reducerPath]: postsApi.reducer },
//   middleware: (getDefaultMiddleware) =>
//     // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
//     getDefaultMiddleware().concat(postsApi.middleware),
// });

export const store = configureStore({
  reducer: {
    user,
    overview,
    details,
    admin,
    approvedSpecialties,
    specialties,
    error,
  },
});
