import { LdsButton, LdsModal, useLdsModal } from "@elilillyco/ux-lds-react";
import "./../../assets/scss/details/comments.scss";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CREATE_COMMENT, UPDATE_COMMENTS } from "../../store/details";
import CommentBox from "./CommentBox";

const Comments = ({ commentList, specialtyList }) => {
  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const [editorData, setEditordata] = useState("");
  const [deleteIndex, setDeleteIndex] = useState("");
  const [commentsToShow, setCommentToShow] = useState([]);
  const [editCommentsData, setEditCommentsData] = useState([]);

  const dispatch = useDispatch();

  const userID = useSelector(({ user }) => user?.user?.uuid || "");
  const { commentUserList } = useSelector(({ details }) => details);

  const { id } = useParams();

  const getCreateUpdateBy = (details) =>
    details ? `${details.first_name} ${details.last_name}` : "";

  const deleteComment = () => {
    const commentObj = {
      list_uuid: id,
      list_title: specialtyList.list_title,
      updated_by: userID,
      active: false,
      comment_uuid: deleteIndex,
    };
    dispatch(UPDATE_COMMENTS(commentObj));
    setIsModalOpen(false);
  };

  const openDeleteModal = (uuid) => {
    setDeleteIndex(uuid);
  };

  const checkCommentListLen = () => commentList.length > 0;

  const commentDateConvert = (date) => {
    const periods = {
      month: 30 * 24 * 60 * 60 * 1000,
      week: 7 * 24 * 60 * 60 * 1000,
      day: 24 * 60 * 60 * 1000,
      hour: 60 * 60 * 1000,
      minute: 60 * 1000,
    };
    const todayDate = new Date();
    const dt = new Date(`${date}Z`);
    // To calculate the time difference of two dates
    const diff = todayDate.getTime() - dt.getTime();
    if (diff > periods.month) {
      // it was at least a month ago
      return `${Math.floor(diff / periods.month)} months ago`;
    }
    if (diff > periods.week) {
      return `${Math.floor(diff / periods.week)} weeks ago`;
    }
    if (diff > periods.day) {
      return `${Math.floor(diff / periods.day)} days ago`;
    }
    if (diff > periods.hour) {
      return `${Math.floor(diff / periods.hour)} hours ago`;
    }
    if (diff > periods.minute) {
      return `${Math.floor(diff / periods.minute)} minutes ago`;
    }
    return "Just now";
  };

  const checkListStatus = () =>
    specialtyList &&
    specialtyList.list_status !== "ARCHIVE" &&
    sessionStorage.getItem("role") !== "spclty_hub_viewer";

  const getMentions = (data) => {
    const matches = data.matchAll(/data-id=\"([^"]+)"/gm);
    const mentions = [];
    for (const match of matches) {
      mentions.push(match[1]);
    }
    return [...new Set(mentions)];
  };

  const postComment = () => {
    const mentions = getMentions(editorData);
    const commentObj = {
      list_uuid: id,
      list_title: specialtyList.list_title,
      comment: editorData,
      created_by: userID,
      mention: mentions,
    };
    dispatch(CREATE_COMMENT(commentObj));
  };

  const editorHide = (index) => {
    const commentsCopy = JSON.parse(JSON.stringify(commentsToShow));
    const editCommentsDataCopy = JSON.parse(JSON.stringify(editCommentsData));
    commentsCopy[index].edit = false;
    editCommentsDataCopy[index] = commentList[index].comment;
    setCommentToShow(commentsCopy);
    setEditCommentsData(editCommentsDataCopy);
  };

  const editorVisible = (index) => {
    const commentsCopy = JSON.parse(JSON.stringify(commentsToShow));
    commentsCopy[index].edit = true;
    setCommentToShow(commentsCopy);
  };

  const editCommentChange = (content, index) => {
    const commentsCopy = JSON.parse(JSON.stringify(editCommentsData));
    commentsCopy[index] = content;
    setEditCommentsData(commentsCopy);
  };

  const saveComment = (index, uuid) => {
    const comment = editCommentsData[index];
    const mentions = getMentions(comment);
    const commentObj = {
      list_uuid: id,
      list_title: specialtyList.list_title,
      comment,
      created_by: userID,
      mention: mentions,
      comment_uuid: uuid,
    };
    dispatch(UPDATE_COMMENTS(commentObj));
    editorHide(index);
  };

  const isSUperAdminRole = () =>
    sessionStorage.getItem("role") === "spclty_hub_super_admin";

  useEffect(() => {
    if (!isModalOpen) {
      setDeleteIndex("");
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (deleteIndex) {
      setIsModalOpen(true);
    }
  }, [deleteIndex]);

  useEffect(() => {
    const commentsCopy = JSON.parse(JSON.stringify(commentList));
    setCommentToShow(
      commentsCopy.map((comment) => ({ ...comment, edit: false }))
    );
    setEditCommentsData(commentsCopy.map((comment) => comment.comment));
  }, [commentList]);

  return (
    <div>
      <div className="px-10">
        <div className="row">
          <div className="col">
            <h2>Comments</h2>
          </div>
        </div>
        <LdsModal
          open={isModalOpen}
          setModalOpen={setIsModalOpen}
          modalId="delete-comment"
          size="col-12 col-sm-9 col-md-6 col-lg-8"
        >
          <h2>Are you sure you want to Delete this Comment?</h2>
          <div className="row">
            <div className="col col-12 col-md-3">
              <LdsButton onClick={deleteComment}>Yes</LdsButton>
            </div>
            <div className="col col-12 col-md-3">
              <LdsButton onClick={() => setIsModalOpen(false)}>No</LdsButton>
            </div>
          </div>
        </LdsModal>
        {checkCommentListLen() ? (
          <>
            {commentsToShow.map((item, index) => (
              <div key={`commentItem_${index}`} className="list-blk">
                <div className="row mt-3">
                  <div className="col col-12 col-md-12">
                    <strong id={`createdby_${index}`}>
                      {getCreateUpdateBy(item.created_by)}
                    </strong>
                    <span
                      id={`createdby_${index}`}
                      className="created-txt ml-2"
                    >
                      {commentDateConvert(item.created_date)}
                    </span>
                    <div className="item-title mt-1">
                      {!item.edit && (
                        <pre
                          id={`createdby_${index}`}
                          dangerouslySetInnerHTML={{
                            __html: item.comment,
                          }}
                        />
                      )}
                      {isSUperAdminRole() && (
                        <>
                          <div>
                            {item.edit ? (
                              <>
                                <CommentBox
                                  id={`editorItem_${index}`}
                                  value={editCommentsData[index]}
                                  setValue={(content) =>
                                    editCommentChange(content, index)
                                  }
                                  suggestions={commentUserList}
                                />
                                <LdsButton
                                  id={`Save_${index}`}
                                  onClick={() => saveComment(index, item.uuid)}
                                  classes="green-btn compact mt-4"
                                >
                                  Save
                                </LdsButton>
                                <LdsButton
                                  id={`Cancel_${index}`}
                                  onClick={() => editorHide(index)}
                                  classes="red-btn compact ml-2 mt-4"
                                >
                                  Cancel
                                </LdsButton>
                              </>
                            ) : (
                              <>
                                <LdsButton
                                  id={`Edit_${index}`}
                                  onClick={() => editorVisible(index)}
                                  classes="compact"
                                >
                                  Edit
                                </LdsButton>
                                <LdsButton
                                  id={`Delete_${index}`}
                                  onClick={() => openDeleteModal(item.uuid)}
                                  classes="red-btn compact ml-2"
                                >
                                  Delete
                                </LdsButton>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <p className="mt-3">No Comment Found</p>
        )}
        {checkListStatus() && (
          <>
            <div className="ql-mention-list-item.selected  ql-mention-list-container-bottom ql-mention-list-container-top mentionContainerclassName">
              <div className="row mt-4">
                <div className="col col-12 col-md-12">
                  {/* add below condition untill we find a solution to render UI after all API data is fetched */}
                  {commentUserList.length > 0 && (
                    <CommentBox
                      id="decision_maker_key_influence_map"
                      value={editorData}
                      setValue={setEditordata}
                      suggestions={commentUserList}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col col-12 col-md-12">
                  <div className="savecl">
                    <LdsButton
                      mode="secondary"
                      className="button-icon-right blue-btn ml-4 mt-4"
                      onClick={postComment}
                    >
                      Save Comment
                    </LdsButton>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Comments;
