import { useEffect, useState } from "react";
import { LdsPagination, LdsLink, LdsCard } from "@elilillyco/ux-lds-react";

import SortableHeader from "../shared/SortableHeader";

import "../../assets/scss/apprSpecialties/approvedSpecialtiesTable.scss";

const ApprovedSpecialtiesTable = ({
  visibleSpecialties,
  setVisibleSpecialties,
  convertDateFormat,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;
  const totalPages = Math.ceil(visibleSpecialties.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const tableHead = [
    "Title",
    "Product",
    "Indication",
    "Quarter",
    "Department",
    "Approved Date",
  ];

  useEffect(() => {
    // navigating the user to 1st page when user sorts the table
    setCurrentPage(1);
  }, [visibleSpecialties]);

  return (
    <>
      {visibleSpecialties.length > 0 ? (
        <>
          <LdsCard>
            <LdsCard.Body>
              <table
                id="approved-speciality-list-table"
                className="lds-table flat"
              >
                <thead>
                  <tr>
                    <SortableHeader
                      headAlign={"align-left b"}
                      header={tableHead}
                      rowData={visibleSpecialties}
                      rowKeyName={[
                        "list_title",
                        "brand",
                        "indication",
                        "list_quarter",
                        "department_field",
                        "updated_date",
                      ]}
                      sortedRowData={setVisibleSpecialties}
                    />
                  </tr>
                </thead>
                <tbody>
                  {visibleSpecialties.map(
                    (itm, index) =>
                      index >= indexOfFirstItem &&
                      index < indexOfLastItem && (
                        <>
                          <tr key={itm.uuid}>
                            <td>
                              <LdsLink href={`/details/${itm.uuid}`}>
                                {itm.list_title}
                              </LdsLink>
                            </td>
                            <td>{itm.brand}</td>
                            <td className="center">{itm.indication}</td>
                            <td>{itm.list_quarter}</td>
                            <td>{itm.department_field}</td>
                            <td>{convertDateFormat(itm.updated_date)}</td>
                          </tr>
                        </>
                      )
                  )}
                </tbody>
              </table>
              <div className="row">
                <div>
                  <LdsPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                  ></LdsPagination>
                </div>
              </div>
            </LdsCard.Body>
          </LdsCard>
        </>
      ) : (
        <div className="row">
          <span className="center">
            <h2>No Record Found</h2>
          </span>
        </div>
      )}
    </>
  );
};
export default ApprovedSpecialtiesTable;
